.language-switch-list {
    @include clearfix;
}

.language-switch-list label {
    display: block;
}

.checkbox-list {
    padding-left: 0;
    margin-left: 0;
    margin-bottom: $spacer;
    list-style: none;
    float: left;

    input {
        display: none;
    }

    li {
        display: inline-block;
    }

    button {
        color: $black;
        background-color: $gray-lighter;
        padding: 10px 13px;
        margin-right: 2px;

        &:focus,
        &:hover {
            color: $brand-brand1;
        }

        &.active {
            background-color: $brand-brand1;
            color: $body-bg;
        }
    }
}

.language-switch-list .dropdown {
    float: left;
}

.language-switch-list .dropdown-toggle {
    padding: 10px 15px;
    background-color: $gray-lighter;
    color: $black;
}

.language-switch {
    display: none;
}

:checked + .language-switch {
    display: block;
}

.language-switch-panel {
    display: none;

    &.active {
        display: block;
    }
}
