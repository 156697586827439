.membership-invite {
    margin-top: 20px;
    text-align: center;

    h2 {
        margin-bottom: 1em;
    }

    article {
        text-align: left;
    }
}
