.popover {
    border-radius: 0;
    border: 0;
    padding: 0;
    color: $body-bg;
    background: $brand-brand1;

    h3,
    h4 {
        font-family: $font-family-sans-serif;
        font-weight: bold;
        text-transform: uppercase;
    }

    .small {
        font-weight: bold;
    }

    .badge {
        padding-top: 3px;
        margin-left: 10px;
    }
}

.popover-header {
    border-radius: 0;
    background: $brand-info;
    border-bottom: 0;
    font-size: $font-size-small;
}

.popover-content {
    @media screen and (max-width: $screen-xs-max) {
        max-height: 320px;
        overflow: auto;
    }
}

.bs-popover-top > .popover-arrow,
.bs-popover-top > .popover-arrow:after {
    border-top-color: $brand-brand1;
}
