.linklist {
    list-style: none;
    padding-left: 0;

    li {
        padding-left: 20px;
        margin-bottom: 3px;
    }

    a {
        font-weight: 600;
    }
}
