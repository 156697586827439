.category-formset__form__category {
    position: relative;

    input {
        @extend .form-control;
        padding-right: 20px;
        width: 100%;
    }
}

.category-formset__template {
    display: none;
}

.category-formset__delete {
    @extend .visually-hidden;

    & ~ .category-formset__delete-label {
        position: absolute;
        right: 7px;
        top: 7px;
        color: $gray;
    }

    &:checked ~ label {
        color: $brand-danger;
    }

    &:checked ~ input {
        color: $brand-danger;
        text-decoration: line-through;
    }
}
