.sort-dropdown {
    margin: 30px 0 20px;
    color: $gray;

    @media screen and (max-width: $screen-xs-max) {
        margin: 20px 0;
    }

    .dropdown-toggle {
        font-weight: bold;
    }
}
