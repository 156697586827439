.dropdown-menu {
    border-radius: 0;
    padding: 0;
}

.dropdown-item {
    border-bottom: 2px solid $gray-lighter;
    width: 100%;
    padding: 0.5 * $spacer $spacer;
    text-align: left;
    text-decoration: none;
    color: $brand-brand1;

    &:hover,
    &:active,
    &:focus {
        color: darken($brand-brand1, 10%);
        background-color: $body-bg;
    }
}
