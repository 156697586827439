.alert {
    border-radius: 0;
}

.alert-text {
    text-align: center;
}

.alert--danger {
    @extend .alert-danger;
}

.alert--info {
    @extend .alert-info;
}

.alert--success {
    @extend .alert-success;
}

.alert__close {
    @extend .btn-close;
    float: right;
    line-height: $spacer;
}

.alert-top {
    margin-bottom: 0;
}
