.dashboard-list {
    list-style-type: none;
    padding: 0;
}

.dashboard-list-listitem {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    clear: right;
    position: relative;
    margin-bottom: 10px;
    min-height: 100px;
}

.dashboard-list-name {
    font-size: $font-size-base;
    margin: 0;
    padding: 0;
}

.dashboard-list-image {
    background-color: $brand-primary;
    height: auto;
    bottom: 0;
    width: 7.5rem;
    background-repeat: no-repeat;
    background-position: center center;
    left: 0;
    position: absolute;
    top: 0;
}

.dashboard-list-body {
    margin-left: 7.5rem;
}

.dashboard-list-content {
    padding: 20px;
}

.dashboard-list-labels {
    padding-top: 20px;
}

.dashboard-list-actions {
    background-color: $gray-light;

    a {
        margin-right: 2px;
    }
}

.dashboard-list-actions:last-child {
    margin: 0;
}
