.comment-box {
    margin: 0 134px;
    padding-top: 35px;

    & .comments_login {
        margin-top: 30px;
    }

    & textarea {
        border: 1px solid $gray;
        border-radius: 0;
        font-size: $font-size-small;
    }

    & textarea:focus {
        border-radius: 0;
        font-size: $font-size-small;
    }

    @media (max-width: $screen-sm-min) {
        margin: 0;
    }
}

.comment {
    margin-bottom: 50px;

    @include clearfix;

    .comment-author {
        color: $black;
        font-family: $font-family-sans-serif;
        text-align: justify;
        font-weight: bold;
        display: inline-block;
        font-size: $font-size-base;

        a {
            vertical-align: bottom;
        }

        span {
            margin-left: 10px;
        }
    }

    & .comment-deleted-author {
        display: inline-block;
        color: $gray;
        font-family: $font-family-sans-serif;
        text-align: justify;
        font-size: $font-size-base;
    }

    .comment-text {
        font-family: $font-family-sans-serif;
        font-size: $font-size-base;
        line-height: 1.6;
        margin-bottom: 10px;
        color: $black;
        white-space: pre-line;
    }

    & .comment-child-list {
        margin: 0 40px;
    }

    & .comment-answer-button {
        float: left;

        padding: 7px 14px;

        @include button-hover-background($brand-primary);
    }

    & .comment-reply-button {
        font-weight: bold;

        i {
            margin-right: 0.5em;
        }
    }

    & .general-form {
        margin-bottom: 88px;
    }

    & .navbar-right {
        margin-right: 0;
    }

    & .navbar-default {
        background-color: transparent;
        border: none;
    }

    & .navbar {
        min-height: initial;
        margin-bottom: 0;
    }

    & .nav {
        padding: 0;

        & .open a {
            background-color: transparent;
        }

        & .dropdown li a,
        & .dropdown li button {
            padding: 10px;
            margin: 0;

            font-family: $font-family-sans-serif;
            font-size: $font-size-small;
            line-height: 1.6;
            text-align: center;
            font-weight: bold;
        }

        & .open a:hover,
        & .open a:focus {
            background-color: transparent;
        }

        & a.active {
            color: $gray-dark;
        }
    }

    & .rating {
        float: left;
    }

    & .action-bar {
        margin-bottom: 10px;

        & .rating {
            float: left;
        }
    }
}

.comment-submission-date {
    margin-left: 10px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: baseline;
    font-size: $font-size-small;
}
