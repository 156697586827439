.info-block {
    .button {
        margin-top: 25px;
    }

    .container-inner {
        @extend .container-narrow;
    }

    h3 {
        margin-top: 3 * $spacer;
    }

    .info-box-text {
        margin-bottom: 0;

        .rich-text {
            text-align: center;
        }
    }

    .info-box-image {
        margin: 0 auto 35px;
    }
}
