.form-section-heading {
    margin-bottom: 20px;
    padding-bottom: 8px;
    border-bottom: 1px solid $gray;

    font-size: $font-size-medium;
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    font-weight: bold;
}

.form-section-fields {
    margin-left: 30px;
}
