.tabs {
    background-color: $brand-brand2;
    margin-top: 20px;

    @media screen and (max-width: $screen-xs-max) {
        padding-top: 10px;
    }

    .herounit + &,
    .timeline + & {
        margin-top: 0;
    }

    .container-narrow {
        margin: 0 auto;
    }

    ul {
        padding-left: 0;
    }

    ul,
    li {
        list-style-type: none;
    }

    li a.active {
        background: $body-bg;
        color: $brand-brand1;
        box-shadow: 0 -9px 10px 3px rgba(0, 0, 0, 0.1);

        @media screen and (max-width: $screen-xs-max) {
            box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.1);
        }
    }

    a {
        color: $black;
        display: block;
        font-weight: bold;
        line-height: 77px;
        text-align: center;
        text-transform: uppercase;

        @media (max-width: $screen-sm-max) {
            line-height: 50px;
        }

        &:hover,
        &:focus {
            color: $brand-brand1;
        }
    }

    .dropdown-menu {
        margin-left: -100px;
        width: 200px;
        left: 50%;
    }
}

.tab-panel {
    margin: 53px 0 86px;

    .js & {
        display: none;
    }

    &.active {
        display: block;
    }

    > h3,
    .idea-list > h2,
    .tab-panel-title {
        font-size: $font-size-large;
        margin: 0 0 12px;
        text-transform: uppercase;
    }

    .tab-panel-times {
        font-size: $font-size-small;
    }

    .tab-panel-description {
        font-size: $font-size-medium;
        line-height: 1.56;

        .idea-list {
            padding-top: 0;
        }
    }
}
