/* stylelint-disable */
$rem-baseline: $font-size-base !default;
$rem: true !default;
$rem-px-only: false !default;

@mixin rem-baseline($zoom: 100%) {
    font-size: $zoom / 1rem * $rem-baseline;
}

@function rem-convert($to, $values...) {
    $result: ();
    $separator: list-separator($values);

    @each $value in $values {
        $item: $value;

        @if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
            $item: $value / 1rem * $rem-baseline;
        }

        @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
            $item: $value / ($rem-baseline / 1rem);
        }

        @else if type-of($value) == "list" {
            $item: rem-convert($to, $value...);
        }
        $result: append($result, $item, $separator);
    }

    @return $result;
}

@function rem($values...) {
    @if $rem-px-only {
        @return rem-convert(px, $values...);
    }

    @else {
        @return rem-convert(rem, $values...);
    }
}

// @mixin rem($properties, $values...) {
//     @if type-of($properties) == "map" {
//         @each $property in map-keys($properties) {
//             $property, map-get($properties, $property);
//         }
//     }
//
//     @else {
//         @each $property in $properties {
//             @if $rem or $rem-px-only {
//                 #{$property}: rem-convert(px, $values...);
//             }
//
//             @if not $rem-px-only {
//                 #{$property}: rem-convert(rem, $values...);
//             }
//         }
//     }
// }

@mixin rotate($degrees) {
    -webkit-transform: rotate(#{$degrees}deg);
    -moz-transform: rotate(#{$degrees}deg);
    -ms-transform: rotate(#{$degrees}deg);
    -o-transform: rotate(#{$degrees}deg);
    transform: rotate(#{$degrees}deg);

    filter: progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=#{cos($degrees)}, M12=-#{sin($degrees)}, M21=#{sin($degrees)}, M22=#{cos($degrees)});
    -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=#{cos($degrees)}, M12=-#{sin($degrees)}, M21=#{sin($degrees)}, M22=#{cos($degrees)})";
    zoom: 1;
}

@mixin aspect-ratio($width: $height) {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@mixin button-hover-text($hover-color) {
    background-color: $gray-lighter;
    color: $black;

    &:hover,
    &:focus {
        color: $hover-color;
    }
}

@mixin button-hover-background($hover-color) {
    background: $body-bg;

    border: 1px solid $hover-color;
    -webkit-appearance: none;

    color: $hover-color;
    transition: background 0.2s, color 0.2s;

    &:hover,
    &:focus {
        color: $body-bg;
        border-color: $hover-color;
        background: $hover-color;
    }

    i {
        display: inline-block;
        margin-right: .5em;
    }
}
