.org-tile {
    @extend %tile;
    margin: 0;
    position: relative;

    &:nth-child(3) {
        margin-right: 0;
    }
}

.org-grid {
    .col-sm-6 {
        padding: 0 10px 20px;
    }

    .col-sm-6:nth-child(3n+1) {
        clear: left;
    }
}

.org-tile-title {
    @extend %tile-title;
    text-align: center;

    a {
        color: $gray-dark;
        padding-right: 0;
        text-decoration: none;

        &:hover,
        &:focus {
            background-color: transparent;
            color: $brand-brand1;
        }
    }
}

.org-description {
    line-height: 1.57;
    margin-bottom: 0;
}

.org-tile-location {
    @extend %tile-location;
}

.org-tile-teaser {
    @extend %tile-teaser-text;
}

.org-title {
    padding-top: 80px;
}

.org-tile-body {
    padding: 30px 50px 58px;
    min-height: 400px;
    max-height: 400px;
    padding-top: 50%;
}

.org-tile-image-link {
    // @include aspect-ratio(35, 26);
    background-color: $brand-brand1;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    text-align: center;

    &:after {
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        border-bottom: 25px solid $body-bg;
        bottom: 0;
        content: " ";
        height: 0;
        left: 50%;
        margin-left: -25px;
        position: absolute;
        width: 0;
    }

    img {
        max-width: 90px;
        margin: 0;
        left: 50%;
        position: absolute;
        top: 25%;
        transform: translate(-50%, -50%);
    }

    .content:before {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 25%;
        margin-left: -75px;
        margin-top: -75px;
        width: 150px;
        height: 150px;
        border-radius: 100px;
        background-color: $body-bg;
        border: solid 4px #f3f3f3;
    }

    @media (max-width: $screen-sm-min) {
        padding: 0;
    }
}

.org-badge-title,
.herounit-location {
    font-size: $font-size-medium;
    font-size: 20px;
    margin: 0;
}
