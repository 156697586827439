.inline-images-block {
    padding-bottom: 0;

    figure {
        display: inline-block;
        text-align: center;
    }
}

.inline-images-block-col {
    padding-bottom: $block-vpadding;

    .img-container {
        display: inline-block;
        position: relative;

        img {
            position: relative;
            z-index: 1;
        }
    }

    .m-no-border .img-container:after {
        display: none;
    }

    .inline-images-text {
        font-size: $font-size-large;
        margin-top: 30px;
        color: $brand-brand1;
        font-family: $font-family-sans-serif;
    }

    &:nth-child(even) {
        .inline-images-text {
            color: $brand-brand2;
        }
    }
}
