.badge {
    padding: 4px 10px;
    font-size: $font-size-small;
    font-style: italic;
    text-transform: uppercase;
    letter-spacing: -0.2px;
    border-radius: 100px;
    font-weight: bold;
    line-height: 1;
}

.badge-private {
    background-color: $brand-brand1;
    text-transform: uppercase;
    color: $body-bg;
}

.badge-runningout {
    background-color: $brand-danger;
    color: $body-bg;
}

.badge-finished {
    background-color: $brand-brand2;
    color: $gray-darker;
    text-transform: uppercase;
}

.badge-archived {
    background-color: $gray;
    color: $body-bg;
    text-transform: uppercase;
}

.badge-type {
    background-color: $black;
}

.badge-secondary {
    // @include label-variant($brand-brand2;
    background-color: $gray-light;
    color: $black;
}

.badge-xs {
    padding: 2px 4px;
    margin-top: -1px;
    font-size: 10px;
    border-radius: 2px;
    line-height: 1;
}

.badge-subtle {
    border-radius: 5px;
    text-transform: none;
    background-color: $gray-lighter;
    color: $gray-darker;
    font-size: 10px;
    padding: 2px 4px;
    font-weight: 600;
    font-style: normal;
}
