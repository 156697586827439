.avatar-small {
    font-size: $font-size-small;
    margin-top: 12px;
    height: 31px;

    img {
        float: left;
        height: 31px;
        width: 31px;
        border-radius: 50%;
        margin-right: 9px;
        background-color: $gray;
    }

    .name {
        font-family: $font-family-sans-serif;
        font-weight: 600;
        color: $gray-darker;
        margin-left: 5px;

        @media screen and (min-width: $screen-sm-min) {
            line-height: 31px;
        }

        &:hover,
        &:focus {
            color: $brand-brand1;
        }
    }
}

.user-avatar {
    height: 2.5em;
    width: 2.5em;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    border-radius: 100%;
    shape-outside: circle();
    margin-right: 0.5em;
}
