.dashboard-header {
    background-color: $brand-brand5;
    padding-top: 2 * $spacer;

    .navbar-brand {
        margin-top: -(1.5 * $spacer);
    }

    .dropdown {
        color: $body-bg;
    }

    .nav-tabs {
        background-color: transparent;

        li {
            a {
                color: $body-bg;
            }

            a:hover {
                background-color: transparent;
            }
        }
    }
}

.dashboard-header-indicator {
    color: $body-bg;
    padding: 1px 7px 2px;
}

.dashboard-header-no-tabs {
    padding-bottom: 26px;
}

.dashboard-header-tab {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: 5px solid $brand-brand5;

    &.active {
        border-top: 5px solid $brand-brand2;

        &.dashboard-header-link {
            color: $black !important;
        }
    }
}

.dashboard-header-logo {
    display: inline-block;
    width: 30px;
    height: 30px;

    img {
        border-radius: 50%;
    }
    background-color: transparent;
}

.dashboard-content {
    margin-top: 40px;
}

.dashboard-content-header:after {
    display: block;
    content: "";
    clear: both;
}

.dashboard-content-header {
    margin-bottom: 40px;
}

.dashboard-content-left {
    display: inline-block;
}

.dashboard-content-right {
    float: right;
}

.dashboard-content-heading {
    margin: 0 0 20px;
    font-size: 24px;
}

.dashboard-content-subheader {
    font-size: $font-size-medium;
}

.dashboard-content-section {
    margin-bottom: 60px;
}
