.membership-request {
    .alert {
        margin-top: 20px;
    }
}

.center-wrapper {
    text-align: center;

    button,
    a {
        text-align: center;
    }
}
