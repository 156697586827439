.timeline {
    background-color: $gray-dark;
    padding: 10px 0 0;
    text-align: center;

    .text-uppercase {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        font-size: $font-size-small;
        font-weight: bold;
        color: $body-bg;
    }

    ul {
        display: inline-block;
        list-style: none;
        padding: 0;
        margin: 0;

        @include clearfix;
    }

    .timeline-phase {
        margin: 0 15px 0 0;
        float: left;

        @media screen and (max-width: $screen-xs-max) {
            margin-right: 10px;
        }
    }

    .timeline-arrow {
        color: $gray;
        margin-right: 10px;

        @media screen and (max-width: $screen-xs-max) {
            margin-right: 5px;
        }
    }

    .dot {
        display: inline-block;
        background-color: transparent;
        border: 2px solid $brand-brand2;
        padding: 0;
        margin-right: 15px;
        margin-bottom: 3px;
        border-radius: 50%;
        height: 17px;
        width: 17px;
        vertical-align: middle;

        transition: transform 0.2s;
        transform-origin: 50% 50%;

        @media screen and (max-width: $screen-xs) {
            width: 23px;
            height: 23px;
        }
    }

    .dot:hover,
    .dot:focus {
        transform: scale(1.25);
    }

    .active .dot {
        background: $brand-brand2;
    }

    li:last-child .dot {
        margin-right: 0;
    }

    .popover {
        pointer-events: none;
        margin-top: -18px;
    }
}
