.custom-dropdown {
    li {
        a,
        button {
            padding: 10px;
            text-align: center;
        }
    }
}

.custom-dropdown-menu {
    border: solid 1px $gray;

    .divider {
        margin: 0;
        background-color: $gray;
    }

    li {
        a,
        button {
            text-transform: uppercase;
            font-size: $font-size-small;
            letter-spacing: -0.2px;
            font-family: $font-family-sans-serif;
            color: $black;
            border-bottom: 0;
        }
    }
}

.action-bar {
    nav {
        background-color: transparent;
        border-color: transparent;
        margin: 0;

        li a {
            padding: 35px 15px 35px 0;
        }
    }

    .navbar {
        padding: 0.5 * $spacer 0;

        li.dropdown-menu {
            @extend .custom-dropdown-menu;
        }
    }

    .dropdown {
        @extend .custom-dropdown;
    }

    .navbar-right .dropdown {
        position: absolute;
        background-color: $body-bg;
    }

    @media (max-width: $screen-sm-min) {
        .navbar-nav {
            float: left;
            margin: 0;
        }

        .navbar-nav > li {
            float: left;
        }

        .navbar-right {
            float: right !important;
            margin-right: -15px;
        }

        .navbar-right .dropdown {
            position: absolute;
            right: 0;
            left: auto;
            background-color: $body-bg;
        }

        .navbar-nav .show .dropdown-menu {
            position: absolute;
            top: 80px;
            left: -80px;
            background-color: $body-bg;
        }
    }

    .show {
        background-color: transparent;
        box-shadow: none;

        a,
        a:focus,
        a:hover {
            background-color: transparent;
        }
    }

    .navbar-default,
    .navbar-nav > .show > a,
    .navbar-default .navbar-nav > .show > a:hover,
    .navbar-default .navbar-nav > .show > a:focus {
        background-color: transparent;
    }

    .dropdown-toggle {
        background-color: transparent;

        &:focus,
        &:hover,
        &:active,
        &:visited {
            background-color: transparent;
            box-shadow: none;
        }
    }
}
