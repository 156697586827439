%divider {
    margin: 40px 0;
    padding: 10px 40px 10px 15px;
    font-size: $font-size-medium;
    font-weight: 600;
    position: relative;
}

.black-divider {
    @extend %divider;
    background: $black;
    color: $body-bg;
}

.gray-divider {
    @extend %divider;
    background: $gray-lighter;
    color: $gray-darker;
}
