.project-tile-container {
    float: left;
}

.project-tile-image-link {
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    display: block;
    position: relative;
    box-sizing: content-box;
}

.project-tile {
    @extend %tile;
    min-height: 490px;
    display: flex;
    flex-direction: column;

    img {
        margin: 0;
    }

    &-finished &-image-link {
        border: solid 5px $brand-brand2;
    }

    &-public &-image-link {
        border: solid 5px $brand-brand3;
    }

    &-running-out &-image-link {
        border: solid 5px $brand-danger;
    }

    &-private &-image-link {
        border: solid 5px $brand-brand1;
    }

    &-archived &-image-link {
        border: solid 5px $gray;
    }

    &-no-image &-image-link {
        background-position: 50% 50%;
    }

    &-no-image &-image-link img {
        display: block;
        margin: 0 auto;
    }

    &-org-avatar {
        border-radius: 50%;
    }

    .badge {
        position: absolute;
        left: 10px;
        bottom: 0;
    }
}

.project-tile-body {
    padding: 16px;
    border-top: 1px solid $gray-lighter;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1 auto;

    .row {
        margin: 0;
    }
}

.project-tile-org-image {
    padding: 0;
}

.project-tile-org-name {
    padding: 0;
    margin-top: 5px;
}

.project-tile-type {
    font-size: $font-size-small;
    margin: 0 0 5px;
    font-style: italic;
    font-weight: bold;
}

.project-tile-title {
    @extend %tile-title;
}

.project-tile-teaser {
    @extend %tile-teaser-text;
    flex: 1 0 auto;
}

.project-tile-image {
    visibility: hidden;
    height: 171px;
}

.project-tile-org {
    @extend %tile-org;
}

.project-other-projects {
    & .black-divider {
        margin: 224px 0 10px;

        & .black-divider-text {
            text-align: center;
        }
    }
}

.project-tile-list {
    padding: 15px;
    min-height: 0;
    display: block;

    .col-md-9 {
        padding-left: 0;
    }

    .col-md-1 {
        padding-right: 10px;
        padding-left: 0;
    }
}

.project-tile-badges {
    margin-top: 10px;
}

.projects-grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .org-project-tile {
        flex-basis: 100%;
    }

    @media screen and (min-width: $screen-sm-min) {
        .org-project-tile {
            flex-basis: 50%;
        }
    }

    @media screen and (min-width: $screen-md-min) {
        .org-project-tile {
            flex-basis: 33%;
        }
    }

    @media screen and (min-width: $screen-lg-min) {
        .org-project-tile {
            flex-basis: 25%;
        }
    }
}
