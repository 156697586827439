.input-gigantic {
    margin: 0 5px 40px;
    position: relative;

    .input-gigantic-field {
        height: 75px;
        padding: 0 30px;
        border: 1px solid $gray-light;
        color: $black;
        display: block;
        font-size: $font-size-small;
        width: 100%;
    }

    .input-gigantic-icon {
        font-size: 44px;
        right: 21px;
        top: 16px;
        position: absolute;
    }
}
