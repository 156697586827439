.rating-button {
    @extend .btn;

    padding: 7px 14px;

    background: transparent;
    border: 1px solid currentcolor;
    cursor: pointer;
    -webkit-appearance: none;

    transition: background 0.2s, color 0.2s;

    i {
        display: inline-block;
        margin-right: 0.5em;
    }

    &.is-read-only {
        cursor: default;
    }
}

.rating-up {
    @include button-hover-background($brand-success);

    &.is-selected {
        background-color: $brand-success;
        border-color: $brand-success;
        color: $body-bg;
    }

    &:disabled {
        background: transparent;
        border-color: $gray;
        color: $gray;

        i {
            color: $gray;
        }
    }
}

.rating-down {
    @include button-hover-background($brand-danger);
    margin: 0 10px;

    &.is-selected {
        background-color: $brand-danger;
        border-color: $brand-danger;
        color: $body-bg;
    }

    &:disabled {
        background: transparent;
        border-color: $gray;
        color: $gray;

        i {
            color: $gray;
        }
    }
}

.idea-ratings .rating {
    position: absolute;
    right: 0;
    top: 0;

    @media (max-width: $screen-sm-min) {
        position: static;
        text-align: center;

        li {
            display: inline-block;
        }
    }
}
