.infonote {
    padding: 20px;
    max-width: 530px;
    margin-bottom: 50px;
    margin-top: 140px;
    background-color: $brand-brand1;
    color: $body-bg;
    text-align: center;
    position: relative;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);

    @media screen and (max-width: $screen-xs-max) {
        margin-top: 95px;
        margin-bottom: 50px;
    }

    img {
        width: 200px;
        margin-left: -100px;
        top: -110px;
        position: absolute;
        left: 50%;
    }
}
