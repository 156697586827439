.accordion {
    text-align: left;
    background: $gray-lighter;

    .card {
        border-radius: 0;
    }

    .card-header {
        padding: 0;
        background-color: inherit;
    }

    .card-body {
        padding-bottom: 50px;
        font-size: $font-size-base;
        line-height: 1.75;

        a {
            color: $brand-brand2;
        }
    }

    .accordion-title {
        margin: 0;
        padding: 5px 0;
        text-align: center;

        i {
            position: absolute;
            right: 1em;
            transition: transform 0.3s;
        }
    }

    .accordion-link {
        font-size: $font-size-small;
        padding: 20px;
        position: relative;
        color: $gray-darker;
        display: block;
        font-family: $font-family-sans-serif;
        line-height: 1.2;
        text-decoration: none;

        &:after {
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -14px;
        }

        &.collapsed {
            border-color: $gray-light;
            background: transparent;
            color: $gray-base;
        }
    }

    .rich-text {
        padding-bottom: 10px;
    }
}

.accordion-content {
    background: transparent;

    .accordion-link,
    .card-body {
        background: $brand-brand1;
        color: $body-bg;
    }

    .accordion-link {
        font-size: $font-size-medium;
        padding: 15px 50px 12px 15px;

        // border: 1px solid $brand-brand1;
        position: relative;
        color: $body-bg;

        &[aria-expanded="true"] i {
            transform: rotate(180deg);
        }
    }

    .accordion-title {
        padding: 0;
        text-align: left;

        .accordion-link {
            &.collapsed:hover {
                color: $gray-base;
            }
        }

        a:hover {
            color: $body-bg;
        }
    }

    .card-body {
        padding: 0 22px 22px;
    }
}
