.maps-popups {
    .leaflet-popup-content {
        margin: 0;
        width: 200px;
    }

    .leaflet-popup-content-wrapper {
        border-radius: 4px;
        padding: 0;
    }

    .maps-popups-popup-meta {
        padding: 4px 10px 0;

        .idea-meta-item {
            margin-right: 10px;
            font-size: $font-size-base;
            font-style: normal;
            font-stretch: normal;
            color: $gray-darker;
        }
    }

    .maps-popups-popup-image {
        height: 100px;
        background-size: cover;
        background-color: $brand-brand1;
    }

    .maps-popups-popup-name {
        font-size: $font-size-base;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        color: $gray-darker;
        font-family: $font-family-sans-serif;
        padding: 4px 10px 16px;
    }
}

.idea-map {
    margin-bottom: 30px;

    @media screen and (min-width: $screen-sm-min) {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
    }

    .map {
        height: 350px;

        @media screen and (min-width: $screen-sm-min) {
            height: 600px;
        }
    }

    .idea-map-buttons {
        z-index: 401;
        position: relative;

        .idea-map-buttons-list {
            position: absolute;
            top: 10px;
            left: 15px;
        }

        .idea-map-buttons-zoom {
            position: absolute;
            top: 10px;
            right: 15px;
        }
    }
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
    background-color: rgba($brand-brand1, 0.6);

    div {
        width: 30px;
        height: 30px;
        margin-left: 5px;
        margin-top: 5px;
        text-align: center;
        border-radius: 15px;
        font-size: $font-size-small;
        background-color: $brand-brand1;
        color: $body-bg;
        line-height: 30px;
    }
}
