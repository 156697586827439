@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-Italic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-Semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-Bold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-BoldItalic.woff") format("woff");
    font-weight: 800;
    font-style: italic;
}
