.document-form {
    .btn {
        padding: 8px 12px;
        margin-bottom: 20px;
    }

    section {
        margin-bottom: 22px;
    }

    .submit-button {
        margin-top: 100px;
    }

    .entry i {
        padding: 7px;
    }
}
