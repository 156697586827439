.circled {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    background: $body-bg center center / cover;
}

.circled-xs {
    height: 30px;
    width: 30px;
}

.circled-lg {
    width: 120px;
    height: 120px;
}

.bio {
    font-style: italic;
    color: $gray;
}

.a4-spacer--right {
    margin-right: $spacer;
}

.a4-muted {
    color: $gray;
}

.u-spinner__container {
    text-align: center;
}
