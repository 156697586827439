.modal-content > .modal-title {
    font-family: $font-family-sans-serif;
    font-weight: normal;

    &:before {
        height: 100px;
        margin-bottom: 40px;
        background: url("/static/images/attention.svg") no-repeat center top;
        background-size: contain;
        content: "";
        display: block;
        width: 100%;
    }
}

.modal.permission {
    .modal-title:before {
        content: none;
    }

    .modal-title .fa,
    .modal-title .fas,
    .modal-title .far {
        display: block;
        text-align: center;
        font-size: 100px;
    }
}

.modal-content {
    border-radius: 0;
    text-align: center;
    box-shadow: none;
    border: 0;

    .modal-footer {
        padding: 60px $spacer;
        border: none;
        text-align: center;
    }

    .modal-body.success {
        padding-bottom: 80px;

        .modal-title:before {
            content: none;
        }

        .fa,
        .far,
        .fas {
            color: $brand-success;
            display: block;
            text-align: center;
            font-size: 100px;
        }
    }

    .modal-header {
        border: none;
    }

    .modal-body {
        padding: 0 60px;

        .input-group {
            padding-bottom: 30px;
        }
    }

    .btn-close {
        opacity: 1;

        & i {
            color: $gray-darker;
        }
    }

    .btn {
        text-transform: uppercase;

        &:active {
            background-color: transparent;
            box-shadow: none;
        }
    }

    .row {
        padding-top: 10px;
    }
}

.modal-backdrop {
    background-color: $primary;
    opacity: 0.9;
}

.report-message {
    margin-top: 80px;
}

.loading {
    cursor: wait;
}

.modal-url-bar-button {
    padding: 0.375 * $spacer 0.75 * $spacer; // reset to bootstrap
}

.modal--close {
    text-align: end;
}
