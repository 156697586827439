.tab-switch {
    margin-bottom: 40px;
    justify-content: center;
    display: flex;
    border-bottom: 2px solid $brand-primary;
    background-color: $body-bg;

    li {
        margin-bottom: -2px;

        a:hover {
            color: $brand-primary;
        }

        &.active {
            box-shadow: none;
            border-top: 2px solid $brand-primary;
            border-right: 2px solid $brand-primary;
            border-left: 2px solid $brand-primary;
        }
    }
}

.tab-switch-active-link,
.tab-switch-active-link:hover {
    color: $brand-primary !important;
}
