.rangebuttons {
    list-style: none;
    padding-left: 0;
    margin-top: 20px;
}

.rangebuttons-item {
    padding-bottom: 40px;
    position: relative;

    input[type="radio"] {
        display: none;
    }

    &:last-child {
        margin-bottom: 0;
    }

    label {
        cursor: pointer;
    }

    &:before {
        position: absolute;
        top: 10px;
        bottom: -5px;
        left: 7px;

        margin: 0 auto;
        width: 1px;

        background: $gray;

        content: "";
    }

    &:last-child:before {
        content: none;
    }
}

.rangebuttons-check {
    position: relative;
    height: 16px;
    width: 16px;

    float: left;

    background-color: $body-bg;
    border: solid 1px $gray;
    border-radius: 100%;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);

    transition: border 0.25s linear;

    &:hover {
        border: solid 1px $gray-lighter;
    }

    &:before {
        position: absolute;
        top: 3px;
        left: 3px;

        display: block;
        height: 8px;
        width: 8px;

        border-radius: 100%;
        margin: auto;

        content: " ";
        transition: background 0.25s linear;
    }

    input[type="radio"]:checked + &:before {
        background: $gray;
    }
}

.rangebuttons-itemlabel {
    display: block;
    overflow: hidden;
    padding-left: 10px;
    font-weight: 400;
}
