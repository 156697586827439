.info-number {
    color: $body-bg;
    background: $brand-danger;
    position: absolute;
    border-radius: 50%;
    top: -0.5em;
    right: 0;
    text-align: center;
    line-height: 2em;
    min-width: 2em;
    font-weight: bold;
}
