.herounit {
    background-size: cover;
    text-align: center;
    min-height: 400px;
    position: relative;
    padding: 40px 0;

    // centering the container vertically and horizontally
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: $screen-xs) {
        min-height: 200px;
        padding: 40px 0;
    }

    &:after {
        background: rgba(0, 0, 0, 0.5);
        content: "\A";
        left: 0;
        min-height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
    }

    a {
        vertical-align: top;
    }
}

.herounit-sm {
    min-height: 200px;
    margin-bottom: 30px;
}

.herounit-description {
    padding: 0 70px;
    line-height: 1.63;
    margin-bottom: 15px;
    font-weight: 600;

    @media screen and (max-width: $screen-sm-max) {
        padding: 0;
    }
}

.herounit-title {
    color: $body-bg;
    font-size: $font-size-xlarge;
    margin: 0 0 10px;
    line-height: 1.4;

    &:after {
        background: rgba(0, 0, 0, 0.5);
        content: "\A";
        left: 0;
        margin: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    @media screen and (max-width: $screen-md-max) {
        padding: 0 40px;
    }

    @media screen and (max-width: $screen-xs) {
        font-size: $font-size-large;
        padding: 0 10px;
    }
}

.herounit-link {
    font-weight: 600;

    & a {
        color: $body-bg;
    }
}

.herounit-links {
    list-style: none;
    padding-left: 0;

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        font-size: 24px;
        color: $body-bg;

        &:hover {
            color: $gray-light;
        }
    }
}

.herounit-link > a:hover {
    color: $gray-light;
}

.herounit-profile > .herounit-links {
    @media screen and (min-width: $screen-sm-min) {
        text-align: left;
        margin-bottom: 0;
    }

    a {
        font-size: $font-size-medium;
    }
}

.herounit-header {
    color: $body-bg;
    position: relative;
    z-index: 1;

    .button {
        margin-top: 13px;
    }

    a:not(.button) {
        color: $body-bg;

        &:hover {
            color: $gray-light;
        }
    }
}

.herounit-profile {
    padding: 80px 0 0;
    align-items: flex-start;
    justify-content: flex-start;

    @media screen and (max-width: $screen-xs-max) {
        padding: 40px 0 0;
        display: block;

        .herounit-links {
            margin-top: 10px;
        }
    }

    .herounit-header {
        float: left;
        position: relative;
        top: 25px;
        display: flex;
        align-items: flex-end;

        @media screen and (max-width: $screen-xs-max) {
            display: block;
            position: static;
            overflow: hidden;
        }
    }

    .circled {
        float: left;
        background-color: $body-bg;

        @media screen and (max-width: $screen-xs-max) {
            float: none;
        }
    }

    .herounit-description {
        overflow: hidden;

        @media screen and (min-width: $screen-sm-min) {
            margin-bottom: 35px;
            padding-left: 20px;
        }
    }

    .herounit-title {
        margin-bottom: 0;
        padding: 0;
    }

    .herounit-meta {
        width: 230px;
        margin: 0 auto;

        p {
            margin-bottom: 0;
            text-align: left;
        }
    }

    .bio,
    strong {
        color: $body-bg;
    }

    .slick-track {
        display: flex;
        align-items: center;
    }
}

.herounit-blue {
    background: $brand-brand1;
}

.herounit-orange {
    background: $brand-brand2;
}

.herounit-turquoise {
    background: $brand-brand3;
}

.herounit-pink {
    background: $brand-brand4;
}

.herounit-purple {
    background: $brand-brand5;
}

.herounit-yellow {
    background: $brand-brand6;

    h1 {
        color: $black;
    }
}

.herounit-blue,
.herounit-orange,
.herounit-turquoise,
.herounit-pink,
.herounit-purple,
.herounit-yellow {
    &:after {
        content: none;
    }
}

.herounit-logo {
    padding: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90px;
    height: 90px;
    margin-bottom: 20px;
    background-color: $body-bg;
    border-radius: 50%;
    position: relative;

    img {
        max-width: 60%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.herounit-logo-lg {
    width: 150px;
    height: 150px;

    @media only screen and (max-width: $screen-xs) {
        width: 90px;
        height: 90px;
    }
}

.herounit-image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.herounit-location {
    @media screen and (max-width: $screen-xs) {
        font-size: $font-size-small;
    }
}

.herounit-location .fa,
.herounit-location .fas,
.herounit-location .far {
    margin-right: 5px;
}
