button {
    border: 0;
    background: transparent;
    appearance: none;

    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.button {
    border-radius: 25px;
    font-size: $font-size-medium;
    padding: 10px 20px;
    background: $brand-brand2;
    color: $gray-darker;
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;

    transition: (color 0.2s, background 0.2s);

    &:focus,
    &:hover {
        background: darken($brand-brand2, 10%);
        color: $gray-darker;
        text-decoration: none;
    }

    &.button-alt {
        background: transparent;
        border: 1px solid $body-bg;
        color: $body-bg;

        &:focus,
        &:hover {
            background: $body-bg;
            color: $gray-base;
        }
    }
}

.btn {
    padding: 10px 20px;
    border: 0;
    font-weight: bold;
    transition: color 0.2s, background 0.2s;
    border-radius: 0;
}

.btn-sm {
    padding: 7px 15px;
}

.btn-hover-primary {
    @include button-hover-text($brand-primary);
}

.btn-hover-danger {
    @include button-hover-text($brand-danger);
}

.btn-hover-success {
    @include button-hover-text($brand-success);
}

.btn-dark {
    background-color: $gray-dark;

    &:hover,
    &:focus {
        color: $gray-light;
        background-color: $gray-darker;
    }
}

.btn-dark-blue {
    background-color: $brand-brand5;
    color: $body-bg;

    &:hover,
    &:focus {
        color: $gray-light;
        background-color: darken($brand-brand5, 10);
    }
}

.btn-round-hero {
    border-radius: 25px;

    @media (min-width: $screen-sm) {
        margin-top: 22px;
    }
}

.btn-round-hero-image {
    border-radius: 25px;

    @media (min-width: $screen-sm) {
        margin-top: 3rem;
    }
}

span.btn-dark:hover,
span.btn-dark:focus {
    background-color: $black;
    color: $body-bg;
}

.btn-gray {
    background-color: $gray-lighter;
    color: $gray-darker;
    font-size: $font-size-small;

    &.btn-primary i {
        color: $gray-darker;
    }

    &:hover,
    &:focus {
        color: $gray;
        background-color: $gray-light;
    }
}

.btn-ghost,
.btn--transparent {
    border: 1px solid currentcolor;
    background: transparent;

    &:hover,
    &:focus,
    &:active {
        background-color: rgba(0, 0, 0, 0.1);
    }

    &.btn-primary {
        color: $brand-primary;

        &:hover,
        &:focus,
        &:active {
            background: transparent;
            color: darken($brand-primary, 20%);
        }
    }

    &.btn-light {
        color: $body-bg;
    }
}

.base-button {
    border-radius: 0;
    padding: 14px 57px;
    font-size: $font-size-small;
    letter-spacing: -0.2px;
    font-weight: bold;
    font-family: $font-family-sans-serif;
}

.submit-button {
    font-size: $font-size-base;

    @extend .btn;
    @extend .btn-primary;
    @extend .base-button;
    background-color: $brand-brand1;
    padding: 10px 20px;
}

.cancel-button {
    font-size: $font-size-base;

    @extend .btn;
    @extend .base-button;
    border: none;
    background-color: transparent;
    color: $black;

    &:focus,
    &:hover,
    &:active,
    &:visited {
        background-color: transparent;
        box-shadow: none;
    }
}

.button-row {
    @media (max-width: $screen-xs) {
        a,
        button {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media (min-width: $screen-xs) {
        .col-6 {
            width: auto;
        }
    }
}

.btngroup {
    display: inline-block;
    overflow: hidden;

    > :first-child {
        margin-left: 0;
    }

    > * {
        margin-left: 1px;
        float: left;
    }

    > span {
        cursor: default;
    }
}

.btngroup-gray {
    background-color: $gray;
}

.btngroup-primary {
    background-color: $brand-brand1;
}

.btn-primary:disabled {
    background-color: $gray-lighter;
    color: $gray-darker;

    &:focus,
    &:hover,
    &:active,
    &:visited {
        background-color: $gray-lighter;
        color: $gray-darker;
    }
}

.btn-wide {
    width: 100%;
}

.btn--primary {
    @extend .btn-primary;
}

.btn--full {
    @extend .btn-wide;
}

.btn--light {
    @extend .btn-gray;
    font-size: $font-size-small;
}

.btn--attached-top {
    margin-bottom: 10px;
}

.btn--link {
    text-decoration: underline;
}
