$sectionslist-gutter: 14px;

.sectionslist {
    ul {
        list-style: none;
        padding: $block-vpadding 0;
        display: flex;
        align-items: stretch;
        margin: 0 - ($sectionslist-gutter / 2);
        justify-content: center;
        flex-wrap: wrap;
    }
}

.sectionslist-section {
    flex-basis: 25%;
    text-align: center;
    padding: 0 $sectionslist-gutter / 2;
    margin-bottom: $sectionslist-gutter;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $screen-md-max) {
        flex-basis: 33.3333%;
    }

    @media screen and (max-width: $screen-sm-max) {
        flex-basis: 50%;
    }

    @media screen and (max-width: $screen-xs-max) {
        flex-basis: 100%;
    }
}

.sectionslist-tile {
    box-shadow: 1px 1px 20px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid $border-color;
    flex-grow: 1;
}

.sectionslist-image {
    height: 250px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.blue {
        background-color: $brand-brand1;
    }

    &.orange {
        background-color: $brand-brand2;
    }

    &.turquoise {
        background-color: $brand-brand3;
    }

    &.pink {
        background-color: $brand-brand4;
    }

    &.purple {
        background-color: $brand-brand5;
    }

    &.yellow {
        background-color: $brand-brand6;
    }
}

.sectionslist-body {
    background-color: $body-bg;
    padding: 15px;

    // stretch the body all the way down
    flex-grow: 1;
}
