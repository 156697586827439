.progress {
    list-style: none;
    padding-left: 0;
    overflow: hidden;
    height: auto;
    background-color: inherit;

    > li {
        padding: 4px 10px;
        margin-left: 10px;
        border-radius: 100px;
        border: solid 1px $brand-brand1;
        float: left;
        font-size: $font-size-small;
        font-weight: 600;
        color: $brand-brand1;
        position: relative;

        &:first-child {
            margin-left: 0;
        }

        &:before {
            content: "";
            position: absolute;
            height: 1px;
            background-color: $brand-brand1;
            width: 11px;
            right: 100%;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        &:first-child:before {
            content: none;
        }
    }

    > .active {
        background-color: $brand-brand1;
        color: $body-bg;
    }
}

.progress__background,
.progress__value {
    stroke-width: 3;
    fill: none;
}

.progress__background {
    stroke: $brand-brand2;
}

.progress__value {
    stroke: $brand-brand5;
    transform: rotate(-90deg);
    transform-origin: center;
}

.progress__label {
    text-anchor: middle;
    font-size: 6px;
}

.progress__check {
    fill: $brand-brand5;
}

.progress__check-unpublished {
    fill: $brand-brand2;
}

.progress-box {
    display: flex;
    align-items: center;
    padding: 1rem;
    border: 1px solid $gray-lighter;
}

.progress-box__progress {
    flex: 1 1 40%;
}

.progress-box__description {
    flex: 1 1 60%;
    margin-left: 1rem;
}
