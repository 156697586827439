.filter-bar {
    @include clearfix;
    font-size: $font-size-small;
    padding-bottom: 10px;

    &.flexbox-list {
        margin-right: 13px;
    }

    label {
        font-weight: bold;
        display: inline-block;
        max-width: 100%;
    }

    .btn {
        background-color: $body-bg;
        border: solid 1px $gray-light;
    }

    .filter-group {
        display: inline-block;
    }

    .open .btn {
        background-color: $gray-light;
    }

    @media (max-width: 50em) {
        .filter-group {
            display: block;
        }

        .dropdown-toggle {
            width: 100%;
            max-width: none;
        }
    }

    .btn-filter .value-label {
        display: inline-block;
    }

    .filter {
        float: left;
        margin-right: 10px;
    }

    .ordering {
        float: right;
    }

    .search-filter {
        width: 100%;
        position: relative;

        .search-filter-btn {
            position: absolute;
            right: 0;
            top: 0;
            padding-bottom: 9px;
        }

        .search-filter-btn:active {
            outline: none;
            border: none;
        }

        .search-filter-btn:focus {
            outline: 0;
        }
    }

    .search-filter-input {
        color: $gray-dark;
        padding: 12px 20px;
        border: 1px solid $gray-light;
        border-right: none;
        min-width: 270px;
        width: 100%;
    }

    .filter-element {
        width: 100%;

        .dropdown-menu {
            max-height: 300px;
            overflow-x: hidden;
        }

        .dropdown-menu a {
            @extend .dropdown-item;
        }
    }

    .btn-group {
        display: inline-block;
    }
}

.no-results {
    text-align: center;
}
