/* stylelint-disable */
$btn-close-bg:          none !default;

$gray-base:             #000000; // not actually used
$gray-darker:           #131313;
$gray-dark:             #2b2b2b;
$gray:                  #818181;
$gray-light:            #d8d8d8;
$gray-lighter:          #f4f4f4;

$brand-brand1:          #0076ae; // Blue
$brand-brand2:          #fcb52d; // Orange
$brand-brand3:          #5cbeb9; // Turquoise
$brand-brand4:          #ef3789; // Pink
$brand-brand5:          #3c487d; // Purple
$brand-brand6:          #ffd102; // Yellow

$primary:               $brand-brand1 !default;
$brand-primary:         $brand-brand1 !default;
$success:               #7ed321 !default;
$brand-success:         $success !default;
$info:                  #41a9e0 !default;
$brand-info:            $info !default;
$warning:               $brand-brand2 !default;
$brand-warning:         $warning !default;
$danger:                #e01431 !default;
$brand-danger:          $danger !default;

$black:                 $gray-darker;

$facebook: #3b589b;

$twitter: #5ea9dd;

$gplus: #dd4b39;

$linkedin: #0086bf;

$spacer: 1rem !default;

// used in wagtail block styles
$block-vpadding:        3.5*$spacer;

// used for footer :p
$footer-height:         6*$spacer;

// used as variable in our scss
//** Global text color on `<body>`.
$text-color:            $gray-dark !default;

// bs default: $link-hover-decoration: underline !default;
// $link-hover-decoration: none !default;


// bs default: $font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-sans-serif:  "Open Sans", Arial, sans-serif !default;


// our own, xlarge and medium addde by us, others also exist in bs
$font-size-base:          1rem !default;
$font-size-large:         1.25rem !default;
$font-size-xlarge:        1.5rem !default;
$font-size-small:         0.8rem !default;
$font-size-medium:        1.125rem !default;


// bs defaults:
// $headings-font-family:    inherit !default;
// $headings-font-weight:    500 !default;
// $headings-font-family:    'Open Sans';
$headings-font-weight:    800 !default;

// needed to not have double carets on dropdowns
$enable-caret: false !default;

// all same colors as $btn-default-*
// $btn-secondary-color:              #333 !default; // $btn-default-color
// $btn-secondary-bg:                 #fff !default; // $btn-default-bg
// $btn-secondary-border:             #ccc !default; // $btn-default-border

// bs default: $btn-link-disabled-color:        $gray-light !default;
// $btn-link-disabled-color:        $gray !default;

// bs defaults in comments after variables
// $btn-border-radius-base:         0; // $border-radius-base !default;
// $btn-border-radius-large:        0; // $border-radius-large !default;
// $btn-border-radius-small:        0; // $border-radius-small !default;


//** Text color for `<input>`s
// $input-color:                    $gray-darker !default; // $gray !default;
//** `<input>` border color
// $input-border:                   $gray !default; // #ccc !default;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
// $input-border-radius:            0; // $border-radius-base !default;
//** Large `.form-control` border radius
// $input-border-radius-large:      0; // $border-radius-large !default;
//** Small `.form-control` border radius
// $input-border-radius-small:      0; // $border-radius-small !default;

//** Border color for inputs on focus
// $input-border-focus:             #41a9e0 !default; // #66afe9 !default;

//** Placeholder text color
// $input-color-placeholder:        $gray !default; // #999 !default;


// $legend-color:                   $gray-darker !default; // $gray-dark !default;
// $legend-border-color:            $gray !default; // #e5e5e5 !default;

//** Hover color for dropdown links.
// $dropdown-link-hover-color:      $brand-brand1 !default; // darken($gray-dark, 5%) !default;
//** Hover background for dropdown links.
// $dropdown-link-hover-bg:         transparent !default; // #f5f5f5 !default;

//** Disabled dropdown menu item background color.
// $dropdown-link-disabled-color:   $gray !default; // $gray-light !default;

//** Text color for headers within dropdown menus.
// $dropdown-header-color:          $gray !default; // $gray-light !default;

// Some of the breakpoints are used in our code. They need to be exchanged for the current variables!
//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  576px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


// Without this, the upload button is not at the very right of the textfield
// (and probably more issues)
$grid-gutter-width:         32px !default; // 30px !default;

// Basics of a navbar
$navbar-height:                    4.5rem !default;

// Navbar links
// $navbar-default-link-color:                $black !default; // #777 !default;
// $navbar-default-link-hover-color:          $brand-brand1 !default; // #333 !default;

// $nav-disabled-link-color:                   $gray !default; // $gray-light !default;
// $nav-disabled-link-hover-color:             $gray !default; // $gray-light !default;

// $nav-tabs-active-link-hover-bg:             #fff !default; // $body-bg !default;
// $nav-tabs-justified-active-link-border-color:     #fff !default; // $body-bg !default;

// $pagination-disabled-color:            $gray !default; // $gray-light !default;

// $state-danger-text:              $brand-danger !default; // #a94442 !default;

//** Popover body background color
// $popover-bg:                          $black !default; // #fff !default;
//** Popover maximum width
// $popover-max-width:                   355px !default; // 276px !default;
//** Popover title background color
// $popover-title-bg:                    #2B2B2B !default; // darken($popover-bg, 3%) !default;
//** Popover arrow width
// $popover-arrow-width:                 25px !default; // 10px !default;

//** Modal backdrop background color
// $modal-backdrop-bg:           #337ab7 !default; // #000 !default;
//** Modal backdrop opacity
// $modal-backdrop-opacity:      .8 !default; // .5 !default;

//** Text color of disabled list items
// $list-group-disabled-color:      $gray !default; // $gray-light !default;

//** Padding around the thumbnail image
// $thumbnail-padding:           0.5rem !default; // 4px !default;
//** Thumbnail background color
// $thumbnail-bg:                #fff !default; // $body-bg !default;
//** Padding around the thumbnail caption
// $thumbnail-caption-padding:   0.5rem !default; // 9px !default;

$badge-line-height:           2 !default; // 1 !default;
$badge-border-radius:         100px !default; // 10px !default;

//** Text muted color
// $text-muted:                  $gray !default; // $gray-light !default;
//** Abbreviations and acronyms border color
// $abbr-border-color:           $gray !default; // $gray-light !default;
//** Headings small color
// $headings-small-color:        $gray !default; // $gray-light !default;
//** Blockquote small color
// $blockquote-small-color:      $gray !default; // $gray-light !default;
//** Blockquote border color
// $blockquote-border-color:     $gray-light !default; // $gray-lighter !default;
//** Page header border color
// $page-header-border-color:    $gray-light !default; // $gray-lighter !default;
//** Point at which .dl-horizontal becomes horizontal
// can not find in bs variables, but also not used in our code
// $dl-horizontal-breakpoint:    $grid-float-breakpoint !default;
//** Horizontal line color.
// $hr-border:                   $gray-light !default; // $gray-lighter !default;
