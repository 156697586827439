.responsive-object {
    position: relative;
    padding-bottom: 67.5%;
    height: 0;
    margin: 10px 0;
    overflow: hidden;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.richtext-image.full-width {
    min-width: 100%;
}

.richtext-image.left,
.richtext-image.right {
    max-width: 100%;

    @media screen and (min-width: $screen-sm-min) {
        max-width: 65%;
    }
}

.richtext-image.left {
    float: left;
    margin-right: $spacer;
}

.richtext-image.right {
    float: right;
    margin-left: $spacer;
}
