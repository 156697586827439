.widget--datetimeinput {
    @include clearfix;
}

.widget--datetimeinput__date {
    display: flex;
}

.datepicker {
    flex: 1 1 auto;
}

// stylelint-disable selector-max-specificity
// need to override the default styling
.date-picker-control:link {
    background-image: none !important;
    min-width: 3em;
    z-index: 0;

    margin-left: -1px;

    @extend .btn;
    @extend .btn--light;

    padding: 7px;

    span:first-child {
        display: inline;

        &:before {
            font-family: "Font Awesome 5 Free", sans-serif;
            font-size: $font-size-large;
            font-weight: 300; // for light, formerly -o
            content: "\f133"; // calendar
        }
    }

    &:focus {
        outline: 2px solid Highlight !important;
        outline: 5px auto -webkit-focus-ring-color !important;
    }

    &:focus,
    &:hover {
        span:first-child:before {
            font-family: "Font Awesome 5 Free", sans-serif;
            font-weight: 300;
            content: "\f073"; // calendar-alt
        }

        span {
            box-shadow: none !important;
            animation: none !important;
        }
    }
}
// stylelint-enable

.date-picker {
    font-family: $font-family-sans-serif;

    // overwrite gradient
    table,
    td {
        background: $body-bg;
    }

    .date-picker-unused {
        background: $gray-lighter;
    }

    .date-picker-highlight {
        color: $text-color;
    }

    .prev-but,
    .next-but {
        font-size: 0;

        &:before {
            font-family: "Font Awesome 5 Free", sans-serif;
            font-weight: 900;
            font-size: $font-size-large;
            vertical-align: bottom;
        }
    }

    .prev-but:before {
        content: "\f104"; // angle-left
    }

    .prev-year:before {
        content: "\f100"; // angle-double-left
    }

    .next-but:before {
        content: "\f105"; // angle-right
    }

    .next-year:before {
        content: "\f101"; // angle-double-right
    }

    .date-picker-hover {
        border: none;

        background: $brand-primary;
        color: $white;
        box-shadow: none;
        text-shadow: none;
    }
}

.timepicker {
    @extend .form-control;
}
