.rss-feed-entry {
    text-align: left;
    border: 1px solid $gray-light;
    padding: 35px 32px;
    margin-bottom: 30px;
    margin-left: 15px;

    .rss-feed-entry-date {
        color: $black;
        font-size: $font-size-small;
        font-weight: bold;
        text-align: justify;
    }

    h4 {
        font-size: 24px;
        line-height: 1.2;
        letter-spacing: -0.2px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    p {
        font-size: $font-size-small;
        line-height: 1.6;
        letter-spacing: -0.2px;
        text-align: justify;
        color: $black;
    }
}

.rss-feed-entries-title {
    text-align: left;
}
