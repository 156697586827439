:target:before {
    content: "";
    display: block;
    height: 80px; /* fixed header height */
    margin: -80px 0 0; /* negative fixed header height */
}

.user-indicator .btngroup .dropdown-item {
    border-bottom: none;
    width: 100%;
}

.user-indicator .btngroup > a {
    height: 45px;
}

.user-indicator a.btn {
    background-color: $brand-brand5;
    color: $body-bg;
    min-width: 45px;
}

.user-indicator {
    a.dropdown-item:active {
        color: $body-bg !important;
        background-color: $brand-brand1 !important;
    }
}

.user-indicator > li > a > .inner {
    border: none;
    display: inline-block;
    position: static;
    padding-left: $spacer;
}

.user-indicator .circled {
    margin-top: -3px;
    margin-right: 10px;
}

.user-indicator .dropdown-menu {
    width: 100%;
    border-radius: 0;
    background: $body-bg;
    overflow-x: hidden;
}

.user-indicator .btngroup {
    display: flex;
    flex-wrap: wrap;
}

.user-indicator .userindicator-profile {
    flex-grow: 1;
    text-align: left;
}

.user-indicator .dropdown-toggle {
    flex-grow: 0;
}

@media screen and (min-width: $screen-xs-min) {
    .user-indicator > .btngroup,
    .user-indicator > .dropdown {
        margin-top: 0.4 * $spacer;
    }

    .user-indicator .btngroup > a {
        display: block;
        height: 50px;
        padding: 0.875rem 10px;
    }

    .user-indicator > li > a > .inner {
        padding: 0;
    }

    .user-indicator .dropdown-menu {
        width: auto;
        max-height: 80vh;
        overflow-y: auto;
    }
}
