.blueprint-tile {
    padding-top: 30px;
    border: solid 1px $gray-light;
    background-color: $brand-brand5;
    display: flex;
    margin: 13px 1.5%;
    flex-direction: column;

    > h2 {
        text-align: center;
        padding: 15px 0;
        margin: 0;
        color: $body-bg;
        font-size: $font-size-large;
    }

    .blueprint-image {
        height: 173px;
        width: 260px;
    }
}

.blueprint-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    .blueprint-tile {
        flex-basis: 30%;
    }
}

.blueprint-list-centered {
    justify-content: center;

    .blueprint-tile {
        flex-basis: 42%;
        max-width: 42%;
        align-items: stretch;
    }
}

.blueprint-description {
    background-color: $body-bg;
    padding: 1em;
}

.blueprint-phases {
    background-color: $body-bg;
    flex-grow: 1;
    padding: 0 1em;
}

.blueprint-footer {
    background-color: $body-bg;
    text-align: center;
    padding: 1em;

    .btn-primary {
        white-space: normal;
    }

    .btn-link {
        display: block;
        margin: auto;
        padding: 10px 0;
        font-weight: bold;
    }
}

.border-bottom {
    border-bottom: 1px solid $gray-light;
    padding: 20px 0;
}

.dst-tile {
    @extend .blueprint-tile;
    color: $body-bg;

    > img {
        height: 173px;
        width: 260px;
    }

    > h2 {
        padding: 0 15px;
    }
}

.dst-lightbox {
    margin-top: 80px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    padding: $spacer;

    h2 {
        text-align: center;
    }

    .dst-lightbox-progress {
        position: relative;
        min-height: 40px;

        .progress {
            margin-bottom: 0;
            position: absolute;
            bottom: 0;
        }
    }

    .blueprint-tile {
        padding: 0;

        .blueprint-image {
            margin: auto;
        }

        > .blueprint-phases {
            padding-top: 10px;
        }
    }
}

.dst-modal-infopage {
    h4 {
        margin-bottom: 20px;
    }

    .modal-content {
        width: 570px;
    }

    .blueprint-modal-image {
        border: 0;
        margin: 0;
        padding-top: 30px;
        background-color: $brand-brand5;
        display: flex;
        flex-direction: column;

        > h2 {
            text-align: center;
            padding: 15px 0;
            margin: 0;
            color: $body-bg;
        }

        > .blueprint-image {
            height: 173px;
            width: 260px;
            display: block;
            margin: auto;
        }
    }

    .blueprint-description > p {
        margin-bottom: 20px;
        margin-left: 35px;
        margin-right: 35px;
    }

    .blueprint-phases .list-unstyled {
        display: inline-block;

        .phase-description {
            width: 400px;
            display: flex;
        }

        .icon {
            flex: 0 0 auto;
            margin-right: 10px;
            align-self: center;
        }

        .icon .fa-stack-1x.onlinephase {
            color: $brand-brand6;
        }

        .icon .fa-stack-2x.onlinephase {
            color: $brand-brand5;
        }

        .icon .fa-stack-1x.offlinephase {
            color: $gray;
        }

        .icon .fa-stack-2x.offlinephase {
            color: $gray-lighter;
        }

        .description {
            text-align: left;
            align-self: center;
        }
    }

    .blueprint-project {
        .project-tile {
            width: 285px;
            margin-left: 143px;
        }
    }

    .blueprint-effort {
        > span {
            display: block;
        }

        .box {
            background-color: $brand-brand5;
            border-radius: 5px;
            max-width: 50%;
            display: block;
            margin: auto;
            color: $brand-brand6;
            font-size: 36px;
            font-weight: bold;
            padding-top: 8px;
            padding-bottom: 8px;
            margin-bottom: 10px;
        }
    }

    .blueprint-guidelines {
        .inspirations {
            padding-left: 160px;
            text-align: left;
        }

        .inspirations > a {
            text-decoration: underline;
        }
    }
}

.dst-description {
    > h3 {
        margin: 0;
    }

    padding: 15px;
    flex-grow: 1;
}

.dst-footer {
    text-align: center;
    padding: 1em;
}

.dst-lightbox-header {
    height: 52px;
    line-height: 52px;
    vertical-align: middle;
    border-bottom: 1px solid $gray-light;
    font-weight: bold;
    color: $gray-darker;

    .dst-lightbox-button {
        color: $gray-darker;
        cursor: pointer;
    }

    .dst-lightbox-info {
        text-transform: uppercase;
    }
}

.dst-lightbox-entry {
    border-bottom: 1px solid $gray-light;
    margin-left: 15px;
    margin-right: 15px;

    ul {
        margin-top: 10px;
        color: $gray;
        padding-left: 20px;
    }
}

.dst-lightbox-lastentry {
    border-bottom: none;
}

h2.dst-lightbox-banner {
    padding: 24px 0;
    margin: 0 -15px; /* work arround boostrap grid */
    background-color: $brand-brand3;
    color: $body-bg;
}

.dashboard-form-buttons {
    .dropdown-toggle {
        border-left: solid 1px $body-bg;
    }

    .dropdown-menu {
        .btn-hover-primary,
        .btn-hover-danger {
            background-color: $body-bg;
        }

        .btn {
            width: 100%;
            border: 0;
            text-align: left;
        }

        .dropdown-text {
            padding-left: 10px;
        }
    }
}

.dst-result-layout {
    .blueprint-list {
        margin: 20px 0;
    }
}
