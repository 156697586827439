ul.popper-container {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    border: 1px solid $border-color;
}

.popper-content--container[data-visible="true"] {
    display: flex;
}

.popper-content--container[data-visible="false"] {
    display: none;
}

.editpoll__menu-container > button {
    @extend .btn-primary;
}

.submenu-item {
    @extend .dropdown-item;

    background-color: $body-bg;

    &:hover {
        color: $brand-primary;
    }
}
