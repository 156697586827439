// Poll

$checkbox-size: 25px;

.poll {
    padding-top: $spacer;
}

.poll__rows {
    display: flex;
    flex-direction: column;
    padding-top: $spacer;
    padding-bottom: $spacer;
    border-bottom: 1px solid $border-color;

    .form-group {
        margin-left: $checkbox-size + 7px !important;
        margin-top: 0.5 * $spacer;
        width: auto;
    }

    .form-control {
        min-height: 48px;
        border-radius: 0;
    }
}

.poll-row__container {
    position: relative;
}

.poll__char-counter {
    color: $gray;
    align-self: flex-end;
    font-size: $font-size-small;
}

.poll-row {
    border: 1px solid $border-color;
    padding: 0.5 * $spacer;
    margin-bottom: $spacer;
    position: relative;
    z-index: 1;
    display: flex;

    @include clearfix;

    &--answered {
        left: 2 * $spacer;
        width: 92%;

        @media (min-width: $screen-md-min) {
            width: 97%;
        }
    }

    input[type="checkbox"]:checked + span {
        font-weight: bold;
    }

    input[type="radio"]:checked + span {
        font-weight: bold;
    }

    .form-control {
        margin-left: 10px;
        margin-top: 0.5 * $spacer;
        width: 98%;
    }

    .poll__char-counter {
        padding-right: 10px;
    }
}

.poll-row.radio {
    flex-direction: column;
    display: flex;
}

.poll-row__number {
    position: absolute;
    right: 0;
}

.poll-row__radio,
.poll-row__number,
.poll-row__label {
    display: inline-block;
    margin-right: 0.5 * $spacer;
}

.editpoll__help-text,
.poll__help-text {
    color: $gray;
    margin-top: -0.7 * $spacer;
    margin-bottom: 0.5 * $spacer;
}

// RESULTS

.poll-row__bar {
    background-color: $brand-brand6;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 0;
    min-width: 0.4 * $spacer;

    transition: width 0.3s;

    @media print {
        border: 1px solid $gray;
    }
}

.poll__btn--light,
.editpoll__btn--question,
.editpoll__btn--delete {
    @extend .btn-light;
}

.poll__btn--dark {
    @extend .btn-primary;
}

.editpoll__btn--question {
    margin-right: $spacer;
}

.poll__btn--wrapper {
    display: flex;
    align-items: center;
}

.poll__btn--link {
    text-decoration: underline;
}

.poll > .poll__btn--link {
    background-color: $brand-primary;
    color: $body-bg;
    text-decoration: none;
}

.poll.poll__btn--wrapper > .poll__btn--link {
    background-color: $body-bg;
    color: $black;
    text-decoration: underline;
}

.poll-row__chosen {
    color: $gray;
    font-size: $font-size-xlarge;
    position: absolute;
    top: 0.75 * $spacer;
}

// Poll text answers

.poll-slider__item {
    padding: $spacer $spacer (2 * $spacer) $spacer;
}

.poll-slider__answer > i {
    color: $gray;
}

.poll-slider__count {
    position: absolute;
    bottom: $spacer;
    left: $spacer;

    @media screen and (min-width: $screen-md-min) {
        left: revert;
        right: 7 * $spacer;
    }
}

.poll-slider {
    position: relative;
    background-color: $gray-lighter;
    margin-bottom: 0.5 * $spacer;

    // slick overwrites - nested for specificity
    .slick-prev,
    .slick-next {
        position: absolute;
        top: revert;
        bottom: $spacer;
        text-align: center;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        color: $body-bg;
        background-color: $brand-primary;
        z-index: 1; // for when tile links overlap

        &:before {
            opacity: 100%;
            font-family: "Font Awesome 5 Free", sans-serif;
            font-weight: 900;
            font-size: $font-size-xlarge;
            line-height: 1.7rem;
        }
    }

    .slick-prev:before {
        content: "\f104"; // angle-left
    }

    .slick-next:before {
        content: "\f105"; // angle-right
    }

    .slick-prev {
        left: revert !important;
        right: 4 * $spacer !important;
    }

    .slick-next {
        right: 1.5 * $spacer !important;
    }

    // Ensure centermode works
    .slick-slide {
        height: auto;
        position: relative;
    }

    .slick-track {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
    }
}
