@import "forms/general-form";
@import "forms/form-control-upload";
@import "forms/input-gigantic";
@import "forms/clear-image";
@import "forms/skinny-form";
@import "forms/radio-action";
@import "forms/form-section";
@import "forms/poll-radio";
@import "forms/editpoll";
@import "forms/widget-radioselect";

// was part of bs4, but not 5 anymore
.form-group {
    margin-bottom: 1rem;
}
