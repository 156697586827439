.a4-comments__commentbox__form,
.a4-comments__child--list,
.a4-comments__comment {
    fieldset {
        margin-bottom: $spacer;
    }
}

.a4-comments__textarea,
.a4-comments__textarea--big,
.a4-comments__textarea--small {
    width: 100%;
    overflow: hidden;
    max-height: 400px;
}

// comments and debate comments have different sized text boxes
.a4-comments__textarea--big {
    min-height: 100px;
}

.a4-comments__textarea--small {
    min-height: 46px;
}

.a4-comments__comment {
    border-top: solid 1px $gray-lighter;
    padding: 1.5 * $spacer 0 $spacer 0;
}

.a4-comments__box--left {
    padding-left: 0;

    @extend .col-md-2;
}

.a4-comments__box--right {
    border-left: 1px solid $gray-lighter;
    padding-right: 0;

    @extend .col-md-10;
}

.a4-comments__filters__parent {
    margin: 1.5 * $spacer 0 1.5 * $spacer 0;
    display: flex;
    flex-direction: column;
}

.a4-comments__filters__btn {
    min-width: 100%;
    margin-bottom: 0.5 * $spacer;
    border: 1px solid currentcolor;

    .fa-check {
        font-size: $font-size-small;
        padding-top: inherit;
    }

    @media screen and (min-width: $screen-md-min) {
        margin-bottom: 0;
    }
}

.a4-comments__filters__search {
    @extend .col-lg-6;
    padding-left: 0;
    margin-bottom: 0.5 * $spacer;
    width: 100%;
    display: flex;

    @media screen and (min-width: $screen-md-min) {
        margin-bottom: 0;
        width: 50%;
    }
}

// moved here because of stylelint complaining
// about specificity. Disabling lines did not
// work.

.a4-comments__filters__show-btn {
    text-align: right;
    display: flex;
    align-items: center;
    color: $gray;

    i {
        order: 2;
    }
}

.a4-comments__dropdown-container {
    margin-right: 2 * $spacer;

    @media screen and (min-width: $screen-sm-min) {
        margin-right: $spacer;
    }

    @media screen and (min-width: $screen-md-min) {
        margin-right: 0;
    }
}

.a4-comments__dropdown {
    text-align: right;

    button i {
        transform: rotate(90deg);
    }
}

.a4-comments__submit-input {
    @extend .submit-button;
}

.a4-comments__filters__search-result {
    position: absolute;
    right: 70px;
    top: 10px;

    .fa-stack {
        font-size: $font-size-sm;
    }
}

.a4-comments__action-bar {
    @media screen and (min-width: $screen-md-min) {
        padding: 0.5 * $spacer 0;
        display: contents;
        justify-content: space-between;
    }
}

.a4-comments__action-bar__btn {
    margin-left: 0.2 * $spacer !important;
}

.a4-comments__action-bar__btn:last-child {
    padding-right: 0; // to allign it with other elements
}

.a4-comments__border--highlighted {
    border-left: 2px solid $brand-primary;
}

.a4-comments__text {
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.a4-comments__submission-date {
    display: block;
    font-style: italic;
    font-size: $font-size-base;
}

.a4-comments__owner {
    border-top: solid 0.5rem $gray-lighter;
}

.a4-comments__anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
    height: 1px;
}

.a4-comments__author {
    display: inline-block;
    margin: 0 (0.25 * $spacer) 0 0;
    font-size: $font-size-large;
    font-family: $font-family-sans-serif;
    font-weight: 800;
    line-height: 1.2;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.a4-comments__moderator {
    font-size: $font-size-sm;
    font-style: italic;
    color: $gray;
}

.a4-comments__child--list {
    border-left: solid 1px $gray-lighter;
    margin: 0 0 $spacer (-$spacer);

    @media screen and (min-width: $screen-md-min) {
        margin: 0 0 2.5 * $spacer 2.5 * $spacer;
    }

    form {
        padding-top: $spacer;
        border-top: solid 1px $gray-lighter;
    }

    .a4-comments__box--left {
        text-align: right;

        @extend .col-md-12;
    }

    .a4-comments__box--right {
        border-left: none;

        @extend .col-12;
    }

    .a4-comments__text {
        margin-top: $spacer;
    }
}

// categories and category badges
.a4-comments__category__input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.a4-comments__category__row {
    input[type="checkbox"] + span:before {
        font-family: "Font Awesome\ 5 Free", sans-serif;
        font-weight: 900;
        font-size: $font-size-small;
        content: "\f067"; // plus
    }

    input[type="checkbox"]:checked + span:before {
        content: "\f00c"; // tick
    }
}

.a4-comments__category__text {
    background-color: $gray;
    border-radius: 1rem;
    text-decoration: none;
    text-transform: uppercase;

    padding: 0.3 * $spacer 0.5 * $spacer;
    margin: 0.5 * $spacer 0.5 * $spacer 0.5 * $spacer 0;
}

.a4-comments__category {
    display: inline-block;
}

.a4-comments__category__que,
.a4-comments__badge--que {
    background-color: $brand-brand1;
}

.a4-comments__category__not,
.a4-comments__badge--not {
    background-color: $brand-brand2;
}

.a4-comments__category__sug,
.a4-comments__badge--sug {
    background-color: $brand-brand3;
}

.a4-comments__badge {
    margin-right: 0.2 * $spacer;
}

.a4-comments__category__input:checked {
    + .a4-comments__category__que {
        background-color: $brand-brand1;
    }

    + .a4-comments__category__not {
        background-color: $brand-brand2;
    }

    + .a4-comments__category__sug {
        background-color: $brand-brand3;
    }
}

.a4-comments__text--highlighted {
    border-left: solid 2px $brand-primary;
    padding-left: 0.5 * $spacer;
}

.modal-url-bar-button.active,
.modal-url-bar-button.active:hover {
    background-color: $brand-success;
}

// old styling
.a4-comments-box {
    margin: 0 134px;
    padding-top: 35px;

    & .a4-comments_login {
        margin-top: 30px;
    }

    & textarea {
        border: 1px solid $gray;
        border-radius: 0;
        font-size: $font-size-small;
    }

    & textarea:focus {
        border-radius: 0;
        font-size: $font-size-small;
    }

    @media (max-width: $screen-sm-min) {
        margin: 0;
    }
}

.a4-comments {
    margin-bottom: 50px;

    @include clearfix;

    .a4-comments-author {
        color: $black;
        font-family: $font-family-sans-serif;
        text-align: justify;
        font-weight: bold;
        display: inline-block;
        font-size: $font-size-base;

        a {
            vertical-align: bottom;
        }

        span {
            margin-left: 10px;
        }
    }

    & .a4-comments-deleted-author {
        display: inline-block;
        color: $gray;
        font-family: $font-family-sans-serif;
        text-align: justify;
        font-size: $font-size-base;
    }

    .a4-comments-text {
        font-family: $font-family-sans-serif;
        font-size: $font-size-base;
        line-height: 1.6;
        margin-bottom: 10px;
        color: $black;
        white-space: pre-line;
    }

    & .a4-comments-child-list {
        margin: 0 40px;
    }

    & .a4-comments-answer-button {
        float: left;

        padding: 7px 14px;

        @include button-hover-background($brand-primary);
    }

    & .a4-comments-reply-button {
        font-weight: bold;

        i {
            margin-right: 0.5em;
        }
    }

    & .general-form {
        margin-bottom: 88px;
    }

    & .navbar-right {
        margin-right: 0;
    }

    & .navbar-default {
        background-color: transparent;
        border: none;
    }

    & .navbar {
        min-height: initial;
        margin-bottom: 0;
    }

    & .nav {
        padding: 0;

        & .open a {
            background-color: transparent;
        }

        & .dropdown li a,
        & .dropdown li button {
            padding: 10px;
            margin: 0;

            font-family: $font-family-sans-serif;
            font-size: $font-size-small;
            line-height: 1.6;
            text-align: center;
            font-weight: bold;
        }

        & .open a:hover,
        & .open a:focus {
            background-color: transparent;
        }

        & a.active {
            color: $gray-dark;
        }
    }

    & .rating {
        float: left;
    }

    & .action-bar {
        margin-bottom: 10px;

        & .rating {
            float: left;
        }
    }
}

.a4-comments-submission-date {
    margin-left: 10px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: baseline;
    font-size: $font-size-small;
}

.a4-comments__filters {
    order: 1;

    @media screen and (min-width: $screen-sm-min) {
        display: flex;
        justify-content: space-between;
    }
}

.a4-comments__filters__parent--closed {
    width: 100%;
    display: flex;
    justify-content: space-between;
    order: 2;
}

.a4-comments__filters__text {
    padding-left: 0;
    margin-top: 0.5 * $spacer;
    color: $gray;
}

.a4-comments__char-count {
    color: $gray !important;
    font-size: $font-size-small !important;
    font-weight: normal !important;
}

.a4-comments__loading {
    text-align: center;
}

.a4-comments__action-bar-container {
    display: flex;
    justify-content: space-between;
    padding-top: 0.5 * $spacer;
    padding-bottom: 0.5 * $spacer;
    flex-wrap: wrap;
}
