.footer {
    @extend .main-menu;
    margin-top: 200px;

    a {
        padding-top: 0;
        padding-bottom: 0;
    }

    .nav {
        margin: 0;
        float: right;

        @media screen and (max-width: $screen-xs-max) {
            padding: 10px 0;
            border-top: 1px solid $gray-light;
            border-bottom: 1px solid $gray-light;
            overflow: hidden;
            clear: left;
            float: none;
        }
    }

    .footer-item {
        float: left;
        margin-left: 8px;

        @media screen and (max-width: $screen-xs-max) {
            float: none;
        }

        // a {
        //     padding: 5px 0;
        // }
    }

    @media screen and (max-width: $screen-sm-max) {
        .container-narrow {
            padding: 0;
        }
    }
}

.social-list {
    margin: 0;
    padding: 0;

    @media screen and (max-width: $screen-sm-max) {
        margin-bottom: 20px;
    }

    &,
    li {
        float: left;
    }

    li {
        margin-right: 8px;
        list-style: none;

        a {
            font-size: 24px;
            display: block;
            padding: 0;
        }

        &.facebook a {
            color: $facebook;
        }

        &.twitter a {
            color: $twitter;
        }

        &.gplus a {
            color: $gplus;
        }

        &.linkedin a {
            color: $linkedin;
        }

        &.euth a {
            color: $primary;
            margin-top: -4px;
        }
    }

    @media (max-width: $screen-sm-min) {
        width: 100%;
    }
}

.footer-disclaimer {
    min-height: $footer-height;
    margin-top: 20px;
    float: left;
    width: 100%;

    .footer-disclaimer-logo {
        max-width: 33%;
        margin-bottom: 20px;

        @media (min-width: $screen-sm-min) {
            margin-right: 40px;
            margin-bottom: 0;
            float: left;
            max-width: 80px;
        }
    }

    .footer-disclaimer-text {
        overflow: hidden;
    }
}
