.commenting-text {
    margin-top: 80px;
}

.commenting-paragraph {
    margin-bottom: 40px;
    word-wrap: break-word;

    @media screen and (min-width: $screen-sm-min) {
        display: flex;
    }

    .btn-primary {
        margin-top: 10px;

        @media screen and (min-width: $screen-sm-min) {
            opacity: 0;
        }
        font-size: $font-size-base;
        transition: opacity 0.2s, color 0.2s, background 0.2s;
    }

    &:hover.comment-count:before {
        background-color: $brand-primary;
        width: 4px;
        left: -2px;
    }

    &:hover.btn-primary {
        opacity: 1;
    }
}

.paragraph {
    @media screen and (max-width: $screen-xs-max) {
        border-right: 4px solid $brand-primary;
    }
}

.comment-count {
    position: relative;

    @media screen and (max-width: $screen-xs-max) {
        text-align: right;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        background-color: $primary;

        transition: width 0.15s, left 0.15s;

        @media screen and (max-width: $screen-xs-max) {
            display: none;
        }
    }

    a:hover,
    a:focus {
        text-decoration: none;
    }
}
