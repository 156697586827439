@import "../fonts/fontcustom/fontcustom.css";

// Core variables and mixins
@import "node_modules/bootstrap/scss/functions";
@import "core/bootstrap-variables";

@import "~bootstrap/scss/bootstrap";

// @import "node_modules/bootstrap/scss/variables";
// @import "node_modules/bootstrap/scss/mixins";

// // Core CSS
// @import "node_modules/bootstrap/scss/grid";
// @import "node_modules/bootstrap/scss/type";
// @import "node_modules/bootstrap/scss/tables";
// @import "node_modules/bootstrap/scss/forms";
// @import "node_modules/bootstrap/scss/buttons";

// // Components
// @import "node_modules/bootstrap/scss/dropdown";
// @import "node_modules/bootstrap/scss/button-group";
// @import "node_modules/bootstrap/scss/input-group";
// @import "node_modules/bootstrap/scss/nav";
// @import "node_modules/bootstrap/scss/navbar";

// @import "node_modules/bootstrap/scss/breadcrumb";
// @import "node_modules/bootstrap/scss/pagination";
// @import "node_modules/bootstrap/scss/badge";
// @import "node_modules/bootstrap/scss/alert";
// @import "node_modules/bootstrap/scss/list-group";
// @import "node_modules/bootstrap/scss/close";

// // Components w/ JavaScript
// @import "node_modules/bootstrap/scss/modal";
// @import "node_modules/bootstrap/scss/tooltip";
// @import "node_modules/bootstrap/scss/popover";

// // Utility classes
// @import "node_modules/bootstrap/scss/utilities";

// Core
@import "core/fonts";
@import "core/mixins";
@import "core/type";
@import "core/containers";
@import "core/buttons";
@import "core/tiles";
@import "core/footer";
@import "core/tabs";
@import "core/utilities";

// Wagtail blocks
@import "wagtail-blocks/blocks";
@import "wagtail-blocks/info-block";
@import "wagtail-blocks/video-block";
@import "wagtail-blocks/inline-images-block";
@import "wagtail-blocks/wide-image-block";
@import "wagtail-blocks/accordion-block";
@import "wagtail-blocks/image-text-list-block";
@import "wagtail-blocks/rss-feed-import";
@import "wagtail-blocks/page-collection";
@import "wagtail-blocks/richtext-block";

// Components
@import "components/a4-comments";
@import "components/accordion";
@import "components/blueprints";
@import "components/datepicker";
@import "components/projects";
@import "components/orgs";
@import "components/menus";
@import "components/hero-unit";
@import "components/commenting-text";
@import "components/language-switch-list";
@import "components/comments";
@import "components/modal";
@import "components/label";
@import "components/alerts";
@import "components/pagination";
@import "components/carousel";
@import "components/table-list";
@import "components/action-bar";
@import "components/dividers";
@import "components/forms";
@import "components/filter_bar";
@import "components/ck-editor";
@import "components/avatars";
@import "components/ideas";
@import "components/dashboard";
@import "components/dashboard-nav";
@import "components/dashboard-list";
@import "components/membership-request";
@import "components/membership-invite";
@import "components/phase-form";
@import "components/category-form";
@import "components/social-login";
@import "components/info-number";
@import "components/timeline";
@import "components/popover";
@import "components/rating";
@import "components/sort-dropdown";
@import "components/document";
@import "components/info-note";
@import "components/user-indicator";
@import "components/usercontent";
@import "components/dropdown";
@import "components/error-pages";
@import "components/teaserlist";
@import "components/sections-list";
@import "components/breadcrumb";
@import "components/linklist";
@import "components/maps";
@import "components/offline-phases";
@import "components/typeahead";
@import "components/tabs";
@import "components/progress";
@import "components/rangebuttons";
@import "components/tab_switch";
@import "components/poll";
@import "components/popper";
@import "components/howto";
