.skinny-form {
    margin: 55px auto 40px;

    @extend .col-md-4;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
    float: none;
    padding: 0;
    text-align: center;

    .form-group {
        margin-left: 0;
        margin-right: 0;
        text-align: left;
    }

    .form-control,
    .col-form-label,
    .btn {
        font-size: $font-size-small;
    }

    .form-control {
        border: 0;
        border-bottom: 2px solid $gray-light;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;

        &.form-error {
            border-color: $brand-danger;
        }

        &:hover,
        &:focus {
            background: $gray-lighter;
        }
    }

    .form-errors {
        font-weight: bold;
        color: $brand-danger;
    }

    .col-form-label {
        color: $gray-light;
        font-weight: normal;
    }

    .errorlist {
        font-size: $font-size-small;
        color: $brand-danger;
        list-style-type: none;
        margin: 0;
        padding: 5px 0 0;
    }
}

.skinny-form-header {
    padding-top: 200px;
    background: $brand-brand1 url("/static/images/logo.svg") no-repeat center 40%;
    background-size: 35%;

    .nav-tabs {
        background-color: transparent;
    }

    .nav-tabs > li {
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        width: 50%;
        padding: 0.5 * $spacer;

        &:first-child {
            margin: 0;
        }

        > a {
            border: 0;
            border-radius: 0;
            color: $body-bg;
        }

        > a:hover,
        > a:focus {
            background: transparent;
            border: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &.active a {
            color: $brand-brand1;
        }

        &.active a:hover,
        &.active a:focus {
            background: $body-bg;
        }
    }
}

.skinny-form-inner {
    padding: 40px;
}
