.form-control {
    border-radius: 0 !important;

    &,
    &:focus {
        background: transparent;
        box-shadow: none;
    }
}

.general-form {
    margin-bottom: 40px;

    label {
        font-size: $font-size-base;
        height: 16px;
        font-weight: bold;
        font-family: $font-family-sans-serif;
        color: $black;
    }

    textarea {
        min-height: 6rem;
        padding: (0.6 * $spacer * 2) 12px;
    }

    .input-group-btn .btn,
    .form-control,
    .form-select {
        min-height: 48px;
        border-radius: 0;
    }

    .errorbar {
        padding: 0.5em;

        color: $brand-danger;
        background-color: $body-bg;
        border: solid 2px $brand-danger;

        &:before {
            display: inline;
            font-family: "Font Awesome 5 Free", sans-serif;
            font-weight: 900;
            content: "\f071 "; // exclamation-triangle
        }
    }

    .infobar {
        padding: 0.5em;

        color: $gray;
        background-color: $body-bg;
        border: solid 2px $gray;

        &:before {
            display: inline;
            font-family: "Font Awesome 5 Free", sans-serif;
            font-weight: 900;
            content: "\f05A "; // info-circle
        }
    }

    .form-inner {
        padding: 1.25 * $spacer 2.5 * $spacer;
        margin: 0 auto;
        max-width: 30rem;

        h1 {
            font-size: $font-size-xlarge;
        }
    }
}

.form-help-text {
    font-style: italic;
    font-size: $font-size-base;
}

.form-hint {
    @extend .form-help-text;
    @extend p;
}

.form-check__label {
    margin-bottom: 1em;
}

.flatpickr-input[readonly] {
    background-color: $body-bg;
}

.input-group {
    width: 100%;
    flex-wrap: nowrap;
}

.input-group__input {
    width: 92%;
    padding: 5px;
    vertical-align: middle;
}

.errorlist {
    display: block;
    position: relative;
    background-color: $brand-danger;
    color: $body-bg;
    font-weight: 600;
    text-align: right;
    padding: 4px 8px 4px 28px;
    margin-top: 0;
    list-style-type: none;

    &:before {
        display: inline;
        position: absolute;
        left: 8px;
        font-family: "Font Awesome 5 Free", sans-serif;
        font-weight: 900;
        content: "\f071"; // exclamation-triangle
    }
}
