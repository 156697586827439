.breadcrumb {
    list-style: none;
    padding-left: 0;
    background-color: inherit;

    li {
        display: inline-block;
        font-size: $font-size-small;

        &:before {
            content: "/";
            margin-right: 5px;
        }

        &:first-child:before {
            content: none;
        }
    }
}
