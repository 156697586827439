nav .pagination {
    a {
        margin-left: 4px;
        padding: 10px 15px 10px 16px;
        color: $gray-darker;
        background-color: $gray-lighter;
        border: none;
        border-radius: 0;
        font-weight: bold;
        font-size: $font-size-small;

        &:hover,
        &:focus {
            color: $brand-brand1;
        }

        @media screen and (max-width: $screen-xs-max) {
            margin-left: 3px;
            padding: 9px 13px;
        }
    }

    li:last-child,
    li:first-child {
        a {
            border-radius: 0;
        }
    }
}

.pagination-row {
    @extend .row;
    margin-right: 13px;
    justify-content: flex-end;
}
