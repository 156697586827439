.image-text-list-block {
    padding: 20px 0;
}

.image-text-list-block-item {
    padding: 10px 0;

    span {
        line-height: 1.3;
    }
}

.image-text-list-block-item-text {
    display: inline-block;
    position: relative;

    span {
        display: inline-block;
        vertical-align: middle;
    }
}
