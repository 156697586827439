.error-banner {
    margin-top: 90px;
    background-color: $brand-brand2;
    border: solid 8px $brand-brand3;
    position: relative;
    height: 350px;
    overflow: visible;

    @media screen and (max-width: $screen-xs-max) {
        height: 220px;
    }

    img {
        @media screen and (max-width: $screen-xs-max) {
            width: 320px;
        }
    }

    .error-banner-header {
        position: absolute;
        top: -60px;
        left: 50%;
        margin-left: -227px;

        @media screen and (max-width: $screen-xs-max) {
            margin-left: -160px;
        }
    }

    .error-banner-header-text {
        position: absolute;
        left: 30px;
        right: 0;
        top: 150px;

        @media screen and (max-width: $screen-xs-max) {
            top: 90px;
        }
    }

    h1 {
        font-size: 100px;
        margin: 0;

        @media screen and (max-width: $screen-xs-max) {
            font-size: 80px;
        }
    }

    h2 {
        font-size: 55px;
        margin: 0;

        @media screen and (max-width: $screen-xs-max) {
            font-size: 35px;
        }
    }
}

.error-info {
    margin-top: 60px;
    font-weight: bold;
    font-size: $font-size-medium;

    .button {
        background-color: $brand-brand1;
        color: $body-bg;
        margin-top: 30px;
    }
}
