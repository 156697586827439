.container-narrow,
.container-medium {
    float: none;
    margin: 0 auto;
    position: relative;
}

.container-narrow {
    @extend .col-md-8;
}

.container-medium {
    @extend .col-md-10;

    padding: 0;
}

.account__container {
    display: flex;
    justify-content: space-between;
}

.account__container--left,
.account__container--right {
    width: 50%;
}

body {
    overflow-x: hidden;
}

img {
    max-width: 100%;
    height: auto !important;
}

.tab-panel p {
    @include clearfix; // stops overlap of img added via ckeditor and floated
}

@media (max-width: $screen-sm) {
    .account__container {
        flex-direction: column;
    }

    .account__container--left,
    .account__container--right {
        width: 100%;
    }
}
