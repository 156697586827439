.upload-wrapper {
    position: relative;

    // @include clearfix();
}

.alert-info {
    margin-bottom: 2px;
}

.form-control-upload {
    position: relative;
    display: block;

    .form-control-file {
        cursor: pointer;
        float: none;
        left: 0;
        position: absolute;
        opacity: 0;
        top: 0;
    }

    .btn {
        line-height: 48px;
    }

    .btn-secondary,
    .btn-default {
        font-size: 24px;
        width: 48px;
        background-color: $brand-success;
        border: 0;
        color: $body-bg;
        padding: 0;
    }

    .btn-danger {
        font-size: 24px;
        width: 48px;
        background: $brand-danger;
        border: 0;
        color: $body-bg;
        padding: 0;
    }

    .alert-info {
        clear: left;
        margin-top: 20px;
    }

    .form-control {
        float: none;
    }

    @media (min-width: $screen-sm) {
        .input-group-btn {
            position: absolute;
            z-index: 10;
            top: 0;
            right: 0;
            width: auto;
        }

        &.col-sm-9 .input-group-btn {
            right: 15px;
        }
    }
}
