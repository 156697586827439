.block-title,
.page-title {
    margin: 0 0 25px;
    text-align: center;
}

.block-inner {
    padding: 0;

    img {
        margin: 20px 0;
    }
}

.rich-text {
    text-align: left;

    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend .block-title;
    }

    p {
        margin: 0 0 $font-size-large;
    }
}

.rich-text__iframe {
    width: 100%;
    height: 100%;
}

.tile-block__tile {
    box-shadow: 1px 1px 20px 5px rgba(0, 0, 0, 0.1);

    a {
        text-decoration: none;
    }

    .card-body {
        padding: 2 * $spacer $spacer 1.5 * $spacer $spacer;
    }

    .block-title {
        font-size: $font-size-xlarge;
    }
}

.block,
.block-inner {
    padding: $block-vpadding 0;
    font-size: $font-size-medium;
    line-height: 1.4;
    text-align: center;

    &.m-highlight,
    &.m-boxed,
    &.m-boxed--vertical,
    & input,
    & textarea {
        color: $body-bg;
    }

    &.m-boxed,
    .m-boxed.m-variation,
    .m-boxed--veritical,
    .m-boxed--vertical.m-variation {
        color: $gray-dark;
    }

    &.m-highlight {
        background: $brand-brand1;

        .button {
            @extend .button;
        }
    }

    &.m-accent {
        background-color: $gray-lighter;
    }

    &.m-highlight-purple {
        background: #3c487d;

        // padding: 140px 0; why?

        img {
            max-width: 100%;
            height: auto;
        }

        .button {
            @extend .button;
        }

        p,
        h3,
        a,
        & {
            // text-align: left;
            color: $body-bg;
        }

        .more {
            float: right;
        }
    }

    &.m-boxed--vertical {
        padding: 0;

        .container-inner {
            @extend .container-medium;
            color: $body-bg;
            padding: $block-vpadding 0;
            background: $brand-brand3;
        }

        .info-box-text {
            @extend .col-md-10;
            float: none;
            margin: 0 auto;
        }

        &.m-variation .container-inner {
            background: $gray-lighter;
            color: $gray-dark;
        }
    }
}

.block + .page-body {
    margin-top: -$block-vpadding;
}

.block-image {
    padding-bottom: $block-vpadding;
    text-align: center;
}

.m-clear {
    clear: left;
}

.m-highlight-purple,
.m-highlight,
.m-highlight-green {
    color: $body-bg;

    .btn-ghost {
        color: $body-bg;
    }
}

.m-highlight-purple {
    background: #3c487d;
}

.m-highlight {
    background: $brand-brand1;
}

.m-highlight-green {
    background-color: $brand-brand3;
}

.block.block-highlighted_column_block {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;

    @media screen and (min-width: $screen-md-min) {
        padding: $block-vpadding 0;
    }
}

.m-before,
.m-after,
.m-cover {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
}

.m-cover {
    right: 0;
    left: 0;
}

.m-before {
    left: 0;
    width: 50%;
}

.m-after {
    right: 0;
    width: 50%;
}

.block-xs-carousel {
    .tile-block__tile {
        margin: $spacer;
        width: auto !important;
    }

    .slick-prev,
    .slick-next {
        font-size: 40px;
        margin-top: -20px;
        position: absolute;
        top: 50%;
        z-index: 1;
        background-color: transparent;
        border: 0;
    }

    .slick-prev {
        left: -20px;
    }

    .slick-next {
        right: -20px;
    }

    .slick-prev.slick-disabled,
    .slick-next.slick-disabled {
        opacity: 0;
    }
}
