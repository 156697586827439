.phase-form {
    background-color: $gray-lighter;
    margin-bottom: 10px;
    margin-left: 20px;
    position: relative;

    .form-control {
        background-color: $body-bg;
    }

    h5 {
        padding: 15px 20px;
        margin-bottom: 0;
    }

    h5 a {
        display: block;
        letter-spacing: 0.1px;
        color: $gray-darker;
        font-family: $font-family-sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        position: relative;
    }

    h5 a:hover {
        color: $brand-brand1;
    }

    h5 .fa,
    h5 .fas,
    h5 .far {
        transition: transform 0.3s;
    }

    .collapsed .fa,
    .collapsed .fas,
    .collapsed .far {
        transform: rotateZ(180deg);
    }
}

.phasefrom-collapse-top {
    position: relative;
}

.phaseform-offline-phase {
    margin-left: 0;

    .phasefrom-collapse-top {
        h5 {
            padding-left: 68px;
            margin-bottom: 0;
        }
    }
}

.phaseform-delete {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
}

.single-phase {
    margin-top: 20px;
    padding: 0 20px 20px;
}
