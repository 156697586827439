.widget-radioselect {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin-left: 1.25em;
    }

    input {
        margin-left: -1.25em;
    }

    label {
        @extend .form-check__label;
        height: auto;
        font-weight: normal;
    }
}
