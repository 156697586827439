.dashboard-left-nav {
    .list-group {
        background-color: $gray-lighter;
        padding: 10px 10px 20px;
    }

    .list-group-item {
        background-color: transparent;
        border: none;
        font-weight: bold;
        border-radius: 0;
        color: $black;
    }

    .active {
        background-color: $body-bg;
        border-left: 2px solid $brand-primary;
        color: $brand-primary;
    }
}

.dashboard-left-nav-header {
    font-size: 20px;
    background-color: $gray-lighter;
    margin: 0;
    padding: 15px 15px 15px 10px;
    border-bottom: 1px solid white;
}

.dashboard-nav-btn {
    margin-bottom: 30px;
    font-size: $font-size-base;
}
