.nav-tabs {
    background-color: $gray-lighter;
    border-bottom: 0;
    padding-top: 10px;

    > li {
        > a {
            font-weight: 600;
            color: $gray-darker;
            padding: 0.5 * $spacer $spacer;
            text-decoration: none;
        }

        > a,
        > a:hover,
        > a:focus {
            border: 0;
        }
    }

    > li:first-child {
        margin-left: 10px;
    }

    > li.active {
        background: $body-bg;
        box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
        margin-top: 1px;

        > a {
            color: $gray-darker;
        }

        > a,
        > a:hover,
        > a:focus {
            border: 0;
        }
    }
}
